import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/7C/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/7C/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/7C/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/7C/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/7B/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/7B/6.jpg';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';

const ClassAssembly7C = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1,
    //     cols: 1,
    //   },

    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 1,
    //   },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      International Disability Day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 7C Date: 13 December 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>

        Students of Class 7C presented their assembly on the topic “International Disability Day’ on Tuesday,
13 December 2022. It focussed on the ability of specially abled persons which changed the
perspective of students, filled their hearts and mind with the beauty of their innate self.
The assembly commenced with a talk on the significance of International Disability Day followed by a
skit and a dance recital by the enthusiastic students.
The beautifully presented assembly attempted to convey that we should be happy with what we have
and we must respect each and every one.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>“I don’t have dis-ability; I have a different-ability.”
        "Believe you can and you're halfway there."
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly7C;